import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import "./style.scss";

interface TaskIntroProps {
  introVideoUrl: string;
  onClose: () => void;
}

export const TaskIntro: React.FC<TaskIntroProps> = ({
  introVideoUrl,
  onClose,
}) => {
  return (
    <div className="task-intro">
      <div className="video-container">
        <iframe
          src={`https://iframe.mediadelivery.net/embed/${introVideoUrl}?autoplay=false&loop=false&muted=false&preload=false&responsive=true`}
          loading="lazy"
          style={{
            border: 0,
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
            borderRadius: "12px",
          }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen={true}
        />

        <button className="closeButton" onClick={onClose}>
          <CloseIcon style={{ fill: "#fff" }} fontSize="large" />
        </button>
      </div>
      <div className="video-overlay" />
    </div>
  );
};
