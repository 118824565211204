import React, { FC, HTMLAttributes, useState } from "react";

import RightArrow from "students/large-arrow-right-icon.svg";
import { TaskIntro } from "./components/VideoPlayer/VideoPlayer";
interface Props {
  introVideoUrl?: string;
  showKickOffButton: boolean;
}

type ButtonProps = HTMLAttributes<HTMLButtonElement>;

const Button: FC<ButtonProps> = ({ children, className, ...buttonProps }) => (
  <button
    className={`common_button standard primary md after ${className}`}
    type="button"
    {...buttonProps}
  >
    {children}
  </button>
);

const MainCTAButton: FC<ButtonProps> = ({ children, ...props }) => (
  <Button {...props}>
    <span>{children}</span>
    <RightArrow />
  </Button>
);

const KickOffButton: FC = () => (
  <MainCTAButton data-action="click->common--turbo-modal#close">
    Let&apos;s kick off
  </MainCTAButton>
);

const WatchAgainButton: FC<ButtonProps> = (props) => (
  <Button {...props} className="mr-1">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12L14 15L10 18M18.9282 13C19.8087 12.2374 20.1614 11.3509 19.9316 10.4779C19.7017 9.6049 18.9021 8.79421 17.6569 8.17158C16.4116 7.54894 14.7902 7.14916 13.0442 7.03423C11.2982 6.91929 9.52513 7.09564 8 7.5359C6.47486 7.97617 5.2829 8.65576 4.60896 9.46927C3.93503 10.2828 3.81679 11.1847 4.27259 12.0353C4.72839 12.8858 5.73275 13.6374 7.12991 14.1734C8.52706 14.7095 10.2389 15 12 15"
        stroke="#ffffff"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <span>Watch the video again</span>
  </Button>
);

const TaskStoryButtons: FC<Props> = ({
  introVideoUrl,
  showKickOffButton,
}: Props) => {
  const [introVideoSeen, setIntroVideoSeen] = useState(false);
  const [introVideoVisible, setIntroVideoVisible] = useState(false);

  const handleVideoButtonClick = () => {
    setIntroVideoVisible(true);
    setIntroVideoSeen(true);
  };

  if (!introVideoUrl) {
    return showKickOffButton ? <KickOffButton /> : null;
  }

  if (introVideoVisible)
    return (
      <TaskIntro
        introVideoUrl={introVideoUrl as string}
        onClose={() => setIntroVideoVisible(false)}
      />
    );

  return introVideoSeen ? (
    <div className="flex">
      <WatchAgainButton onClick={handleVideoButtonClick} />
      {showKickOffButton && <KickOffButton />}
    </div>
  ) : (
    <MainCTAButton onClick={handleVideoButtonClick}>
      Watch the video
    </MainCTAButton>
  );
};

export default TaskStoryButtons;
